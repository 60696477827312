
  import CustomSlider from '@/components/CustomSlider';
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    name: 'SectionActivityCarousel',
    components: {
      CustomSlider,
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey: function (props) {
      return (
        'section-activity-carousel-' +
        props.section.id +
        '-' +
        (props.section.route_name
          ? props.section.route_name
          : 'landingpages-activities-activity')
      );
    },
    data() {
      return {
        sliderElements: [],
      };
    },
    computed: {
      numberOfElements() {
        if (
          this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.xs
        ) {
          return 'two_2';
        } else if (
          this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.sm
        ) {
          return 'three_3';
        } else {
          return this.section.number_of_elements ?? 'three_3';
        }
      },
    },
    created() {
      // prepare carousel items
      if (this.section.activities) {
        this.section.activities.forEach((activity) => {
          if (activity.card_image !== null) {
            // differ target link for routing
            const params = {};
            const route_name = this.section.route_name
              ? this.section.route_name
              : 'landingpages-activities-activity';
            params.activity = activity.slug;

            if (this.section.city) {
              params.city = this.section.city.slug;
            }

            const route = {
              name: route_name,
              params: params,
            };

            let link_title = '';
            if (this.section.link_title) {
              link_title = this.section.link_title.replace(
                /%activity_name/g,
                activity.name
              );
            }

            this.sliderElements.push({
              text: activity.title,
              image: {
                url: prepareImageUrl(activity.card_image.url),
                alt: activity.card_image.alternativeText,
              },
              link: this.localePath(route),
              link_title: link_title,
            });
          }
        });
      }
    },
  };
